import React, { useState, useEffect, useCallback, useMemo } from "react";
import "./styles.css";
import { withAuthenticator, Connect } from "aws-amplify-react";
import { useMutation } from "@apollo/react-hooks";
import ScrollToBottom from "react-scroll-to-bottom";
import gql from "graphql-tag";
import Avatar from "react-avatar";
import { v4 as uuidv4 } from "uuid";
import { API, Auth, graphqlOperation } from "aws-amplify";
import {
  Card,
  Divider,
  Header,
  Icon,
  Image,
  Loader,
  Message,
} from "semantic-ui-react";
import { useParams } from "react-router-dom";
import ChatInput from "../../components/atoms/ChatInput";
import ConversationParticipants from "../../components/organisms/ConversationParticipants";
import BaseLayout from "../../components/templates/BaseLayout";
import { allMessageConnection } from "../../graphql/queries";
import { subscribeToNewMessage } from "../../graphql/subscriptions";
import {
  createMessage,
  addSupportConversation,
  createUserConversations,
  conversationReadedByAdmin,
} from "../../graphql/mutations";
import { API_GATEWAY } from "../../constants/Urls";
import axios from "axios";
import moment from "moment";
import {
  BreadcrumbExampleShorthand,
  getMessageContent,
  getSessionStoreValue,
} from "../../components/molecules/functions";

const CREATE_MESSAGE = gql(createMessage);
const ADD_SUPPORT_CONVERSATION = gql(addSupportConversation);
const CREATE_USER_CONVERSATION = gql(createUserConversations);
const ADMIN_READ_CONVERSATION = gql(conversationReadedByAdmin);

const ConversationDetails = ({ conversationId }) => {
  const [readConvesationbyAdminMutation] = useMutation(ADMIN_READ_CONVERSATION);
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(true);

  const handleMarkReadConversation = async () => {
    try {
      const response = await readConvesationbyAdminMutation({
        variables: { conversationId, isRead: true },
      });
      if (response?.data?.statusCode === 200) {
        console.log(response?.data?.message);
      }
    } catch (error) {
      console.error("Error marking conversation as read:", error);
    }
  };

  // Fetch messages and store in state
  useEffect(() => {
    setLoadingMessages(true);
    API.graphql(graphqlOperation(allMessageConnection, { conversationId }))
      .then(({ data }) => {
        setMessages(data?.allMessageConnection?.messages || []);
        setLoadingMessages(false);
      })
      .catch((error) => {
        console.error("Error fetching messages:", error);
        setLoadingMessages(false);
      });
  }, [conversationId]);

  // Call mark-as-read when messages are fully loaded
  useEffect(() => {
    if (!loadingMessages && messages.length > 0) {
      handleMarkReadConversation();
    }
  }, [loadingMessages, messages]);

  // ✅ Update messages in real-time when a new message is received
  const handleNewMessage = (prev, { subscribeToNewMessage: newMessage }) => {
    setMessages((prevMessages) => [newMessage, ...prevMessages]); // Add new message at the start
    return {
      ...prev,
      allMessageConnection: {
        ...prev.allMessageConnection,
        messages: [newMessage, ...prev.allMessageConnection.messages], // Insert new message at the first index
      },
    };
  };

  const renderMessages = useMemo(
    () => (
      <ScrollToBottom className="messageList">
        {messages
          ?.slice()
          .reverse()
          .map(
            ({ id, content, image, author, createdAt, sender: senderId }) => {
              const { userType, firstName, lastName } = author;
              const messageDate = moment(createdAt, "x").format(
                "DD ddd MMM YYYY HH:mm"
              );
              const profileLink =
                userType === "Customers"
                  ? `/customer/${senderId}`
                  : `/maid/${senderId}`;

              return (
                <li key={id}>
                  <Avatar
                    name={`${firstName} ${lastName}`}
                    size={30}
                    round="15px"
                    style={{ float: "left", marginRight: "12px" }}
                  />
                  <div>
                    <a className="messageLink" href={profileLink}>
                      {firstName} {lastName}
                    </a>
                    <span className="messageTime">{messageDate}</span>
                    <div>
                      {content && <span>{getMessageContent(content)}</span>}
                      {image && <Image src={image} size="small" />}
                    </div>
                  </div>
                </li>
              );
            }
          )}
      </ScrollToBottom>
    ),
    [messages] // ✅ Ensures re-render when messages change
  );

  return (
    <Connect
      query={graphqlOperation(allMessageConnection, { conversationId })}
      subscription={graphqlOperation(subscribeToNewMessage, { conversationId })}
      onSubscriptionMsg={handleNewMessage} // ✅ Real-time updates
    >
      {({ loading }) => {
        if (loading) {
          return (
            <Message icon>
              <Icon name="circle notched" loading />
              <Message.Content>
                <Message.Header>Just one second</Message.Header>
                Processing your request.
              </Message.Content>
            </Message>
          );
        }

        return renderMessages;
      }}
    </Connect>
  );
};

const Conversation = ({ location }) => {
  const prevNavigation = getSessionStoreValue({ location });
  const { id } = useParams();
  const isSupport = location.search === "?s=1";
  const [addMessage] = useMutation(CREATE_MESSAGE);
  const [createSupportConversation] = useMutation(ADD_SUPPORT_CONVERSATION);
  const [addUserConversation] = useMutation(CREATE_USER_CONVERSATION);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const [suggestionsByAI, setSuggestionsByAI] = useState([]);
  const [messageInput, setMessageInput] = useState("");

  const fetchSuggestions = useCallback(async () => {
    setIsLoadingSuggestions(true);
    try {
      const url = `${API_GATEWAY}prod/get-reply-suggesstions?conversationId=${id}`;
      const session = await Auth.currentSession(); // Retrieve the session object
      const idToken = session.getIdToken(); // Invoke getIdToken on the session object
      const token = idToken.getJwtToken(); // Get the JWT token string
      const response = await axios.get(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorizer: token,
        },
      });

      if (response.status === 200) setSuggestionsByAI(response.data || []);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    } finally {
      setIsLoadingSuggestions(false);
    }
  }, [id]);

  useEffect(() => {
    if (isSupport) {
      createSupportConversation({
        variables: { input: { id, createdAt: Date.now(), name: id } },
      });
      addUserConversation({
        variables: { conversationId: id, userId: id, isSupport: true },
      });
    }
    fetchSuggestions();
  }, [
    createSupportConversation,
    addUserConversation,
    id,
    isSupport,
    fetchSuggestions,
  ]);

  const handleMessageSubmit = useCallback(
    async (message) => {
      const result = await addMessage({
        variables: {
          content: message,
          createdAt: Date.now(),
          conversationId: id,
          id: uuidv4(),
        },
      });
      return result.data.createMessage;
    },
    [addMessage, id]
  );

  const breadCrumbs = useMemo(
    () => [
      { key: "home", content: "Home", link: true, path: "/" },
      prevNavigation?.state?.prevPathName === "Customer" && {
        key: "customers",
        content: "Customers",
        link: true,
        path: "/customers",
      },
      prevNavigation?.state?.prevPathName === "Maid" && {
        key: "maids",
        content: "Maids",
        link: true,
        path: "/maids",
      },
      prevNavigation?.state?.prevPathName && {
        key: prevNavigation?.state?.prevPathName,
        content: prevNavigation?.state?.prevPathName,
        link: true,
        path: prevNavigation?.state?.prevPathUrl,
      },
      { key: "conversations", content: "Conversations", active: true },
    ],
    [prevNavigation]
  );

  return (
    <BaseLayout>
      <div className="mt-15">{BreadcrumbExampleShorthand(breadCrumbs)}</div>
      <Divider horizontal>
        <Header as="h4">
          <Icon name="conversation" />
          Conversation
        </Header>
      </Divider>
      <ConversationParticipants conversationId={id} />
      <ConversationDetails conversationId={id} />
      <div>
        {isLoadingSuggestions ? (
          <Loader active inline="centered" size="large" />
        ) : (
          <Card.Group itemsPerRow={suggestionsByAI.length}>
            {suggestionsByAI.map(({ content }, index) => (
              <Card key={index} onClick={() => setMessageInput(content)}>
                <Card.Content>
                  <Card.Description>{content}</Card.Description>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
        )}
      </div>
      <ChatInput
        onKeyDown={handleMessageSubmit}
        messageInput={messageInput}
        setMessageInput={setMessageInput}
        theCallBack={fetchSuggestions}
      />
    </BaseLayout>
  );
};

export default withAuthenticator(Conversation);
