import React, { useState } from "react";
import BaseLayout from "../../components/templates/BaseLayout";
import "./styles.css";
import CustomDropdown from "../../components/atoms/DropdownList/customSelect";
import {
  Form,
  Grid,
  Header,
  TextArea,
  Icon,
  Input,
  Button,
  Label,
  Divider,
  Checkbox,
} from "semantic-ui-react";
import DropdownList from "../../components/atoms/DropdownList";
import SweetAlert from "react-bootstrap-sweetalert";
import { API_GATEWAY } from "../../constants/Urls";
import { Auth } from "aws-amplify";
import axios from "axios";
import { BreadcrumbExampleShorthand } from "../../components/molecules/functions";
import FetchHistoryPerformanceTableData from "./FetchHistoryPerformanceTableData";

const PushNotifications = () => {
  const [selectedUserType, setSelectedUserType] = useState("Maids");
  const [selectedCategory, setSelectedCategory] = useState("Active");
  const [notificationTitle, setNotificationTitle] = useState("");
  const [message, setMessage] = useState("");
  const [selectedDeepLinkOption, setSelectedDeepLinkOption] = useState({
    label: "Homepage",
    value: "",
  });
  const [isConfirmPopup, setIsConfirmPopup] = useState(false);
  const [isNotificaitonSending, setIsNotificaitonSending] = useState(false);
  const [isSuccessNotifiedPopup, setIsSuccessNotifiedPopup] = useState(false);
  const [errors, setErrors] = useState({ title: false, message: false });
  const [refreshTable, setRefreshTable] = useState(false);
  const [sendAsSupport, setSendAsSupport] = useState(false);

  const isBtnDisabled = !selectedUserType || !selectedCategory;

  const userOptions = [
    { text: "Maids", value: "Maids" },
    { text: "Clients", value: "Clients" },
  ];
  const categoryOptions = [{ text: "Active", value: "Active" }];

  const validateFields = () => {
    const newErrors = {
      title: !notificationTitle.trim(),
      message: !message.trim(),
    };
    setErrors(newErrors);
    return !newErrors.title && !newErrors.message;
  };

  const handleConfirmPopup = () => {
    return (
      <SweetAlert
        warning
        showCancel
        title={`Are you sure to send notification to ${selectedCategory.toLowerCase()} ${selectedUserType.toLowerCase()} ?`}
        onConfirm={() => handleSendPushNotification()}
        onCancel={() => setIsConfirmPopup(false)}
        customButtons={
          <React.Fragment>
            <Button secondary onClick={() => setIsConfirmPopup(false)}>
              No
            </Button>
            <Button primary onClick={() => handleSendPushNotification()}>
              Yes
            </Button>
          </React.Fragment>
        }
      ></SweetAlert>
    );
  };

  const handleSuccessPopup = () => {
    return (
      <SweetAlert
        success
        title="Good job!"
        onConfirm={() => {
          setIsSuccessNotifiedPopup(false);
          setRefreshTable((prev) => !prev);
        }}
        customButtons={
          <React.Fragment>
            <Button
              primary
              onClick={() => {
                setIsSuccessNotifiedPopup(false);
                setRefreshTable((prev) => !prev);
              }}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        {`Notification sent to ${selectedCategory} ${selectedUserType}`}
      </SweetAlert>
    );
  };

  const handleSendPushNotification = async () => {
    if (!validateFields()) return;
    setIsConfirmPopup(false);
    setIsNotificaitonSending(true);
    const notificationBody = {
      userType:
        selectedUserType === "Clients"
          ? "customers"
          : selectedUserType.toLowerCase(),
      category: selectedCategory.toUpperCase(),
      data: {
        title: notificationTitle,
        body: message,
        deeplink: selectedDeepLinkOption.value,
        sendAsSupport,
      },
    };
    try {
      const objCurrentSession = await Auth.currentSession();
      const idToken = objCurrentSession.getIdToken();
      await axios.post(
        `${API_GATEWAY}prod/admin-push-notifications`,
        notificationBody,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorizer: idToken.getJwtToken(),
          },
        }
      );
      setIsNotificaitonSending(false);
      setIsSuccessNotifiedPopup(true);
    } catch (error) {
      console.error("Error sending notification", error);
      setIsConfirmPopup(false);
      setIsNotificaitonSending(false);
    }
  };

  const breadCrumbs = [
    { key: "home", content: "Home", link: true, path: "/" },
    {
      key: "sendPushNotifications",
      content: "Send Push Notifications",
      active: true,
    },
  ];

  return (
    <div className="push-notification-layout">
      <BaseLayout className="loader">
        {isConfirmPopup && handleConfirmPopup()}
        {isSuccessNotifiedPopup && handleSuccessPopup()}
        <Grid>
          <div className="mt-15">{BreadcrumbExampleShorthand(breadCrumbs)}</div>
          <Grid.Row>
            <Grid.Column>
              <Header as="h2">Send Push Notifications</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column className="border-end" width={4}>
              <div className="user-filter-section mb-30">
                <span className="notifications-label">Select user type</span>
                <div className="select-dropdown">
                  <DropdownList
                    options={userOptions}
                    placeholder="Select user"
                    value={selectedUserType}
                    onChange={(event, { value }) => {
                      event.preventDefault();
                      setSelectedUserType(value);
                      if (!selectedCategory) {
                        setSelectedCategory("Active");
                      }
                    }}
                  />
                </div>
              </div>
              <div className="category-filter-section mb-30">
                <span className="notifications-label">Select a category</span>

                <div className="select-dropdown">
                  <DropdownList
                    options={categoryOptions}
                    placeholder="Select category"
                    value={selectedCategory}
                    onChange={(event, { value }) => {
                      event.preventDefault();
                      setSelectedCategory(value);
                      if (!selectedUserType) {
                        setSelectedUserType("Maids");
                      }
                    }}
                  />
                </div>
              </div>
            </Grid.Column>

            <Grid.Column width={5}>
              <div className="title-input-section mb-30">
                <label className="notifications-label">
                  Title <span>{`(only for Android) 65 chars max`}</span>
                </label>
                <Input
                  fluid
                  placeholder="Type your title here"
                  maxLength="65"
                  value={notificationTitle}
                  onChange={(event, data) => {
                    event.preventDefault();
                    setNotificationTitle(data.value);
                    setErrors((prev) => ({ ...prev, title: false }));
                  }}
                />
                {errors.title && (
                  <span className="text-danger">Title is required</span>
                )}
              </div>
              <div className="message-input-section mb-30">
                <label className="notifications-label">
                  Message <span>178 chars max</span>
                </label>
                <Form.Field
                  rows={8}
                  control={TextArea}
                  placeholder="Tell us more about you..."
                  maxLength="178"
                  value={message}
                  onChange={(event, data) => {
                    event.preventDefault();
                    setMessage(data.value);
                    setErrors((prev) => ({ ...prev, message: false }));
                  }}
                />
                {errors.message && (
                  <span className="text-danger">Message is required</span>
                )}
              </div>
              <div className="deeplink-filter-section mb-16">
                <label className="notifications-label">
                  Deeplink to screen
                </label>

                <CustomDropdown
                  setSelectedDeepLinkOption={setSelectedDeepLinkOption}
                  selectedDeepLinkOption={selectedDeepLinkOption}
                  selectedUserType={selectedUserType}
                />
              </div>
              <div>
                <div className="mb-16">
                  <Checkbox
                    label="Send push notifications as support"
                    onClick={() => setSendAsSupport(!sendAsSupport)}
                  />
                </div>
              </div>
              <div className="send-notification-button mt-20">
                <Button
                  color={!isBtnDisabled && !isNotificaitonSending && "green"}
                  className="ui success button"
                  disabled={isBtnDisabled || isNotificaitonSending}
                  loading={isNotificaitonSending}
                  onClick={() => validateFields() && setIsConfirmPopup(true)}
                >
                  {`${
                    isNotificaitonSending
                      ? `Notification Sending to ${selectedCategory} ${selectedUserType}`
                      : `Send Push Notification to ${selectedUserType}`
                  }`}
                </Button>
                {isNotificaitonSending && (
                  <Label basic color="red" pointing>
                    Please do not perform any action while the notification is
                    being sent.
                  </Label>
                )}
              </div>
            </Grid.Column>

            <Grid.Column width={5}>
              <div className="tag-notification">
                <Icon name="info circle"></Icon>
                <p>
                  Tags you can use in your notification:
                  <span>{`{{ first_name }}, {{ last_name }}`}</span>
                </p>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        <div className="history-performance-table">
          <Grid>
            <Grid.Row className="table-header">
              <Grid.Column className="pl-0" width={15}>
                <Header as="h2">History and Performance</Header>
              </Grid.Column>
              <Grid.Column className="icon-refresh">
                <Icon
                  className="refresh-icon"
                  name="sync"
                  size="large"
                  title="Refresh table"
                  onClick={() => setRefreshTable((prev) => !prev)}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <FetchHistoryPerformanceTableData key={refreshTable} />
            </Grid.Row>
          </Grid>
        </div>
      </BaseLayout>
    </div>
  );
};

export default PushNotifications;
