import React from "react";
import { Connect } from "aws-amplify-react";
import { getHistoryPerformanceData } from "../../graphql/queries";
import moment from "moment";
import { graphqlOperation } from "aws-amplify";
import { Icon, Message, Table } from "semantic-ui-react";

const FetchHistoryPerformanceTableData = () => {
    return (
      <Connect query={graphqlOperation(getHistoryPerformanceData)}>
        {({
          data: { getPushNotificationFromAdminHistory },
          loading,
          error,
        }) => {
          if (loading || !getPushNotificationFromAdminHistory)
            return (
              <Message icon>
                <Icon name="circle notched" loading />
                <Message.Content>
                  <Message.Header>Just one second</Message.Header>
                  Processing your request.
                </Message.Content>
              </Message>
            );
            const sortNotifications = (notifications) => {
              const statusOrder = { "PENDING": 1, "IN PROGRESS": 2, "COMPLETED": 3 };
          
              return notifications.sort((a, b) => {
                  const statusA = statusOrder[a.status.toUpperCase()] || 99;
                  const statusB = statusOrder[b.status.toUpperCase()] || 99;
          
                  if (statusA !== statusB) {
                      return statusA - statusB;
                  }
                  return new Date(b.dateCreated) - new Date(a.dateCreated);
              });
          };
          const sortedNotifications = sortNotifications(getPushNotificationFromAdminHistory);
          return (
            <Table basic="very" celled>
              <Table.Header>
                <Table.Row className="pt-0">
                  <Table.HeaderCell>Sent on</Table.HeaderCell>
                  <Table.HeaderCell>Sent to</Table.HeaderCell>
                  <Table.HeaderCell>Category</Table.HeaderCell>
                  <Table.HeaderCell>Title</Table.HeaderCell>
                  <Table.HeaderCell>Message</Table.HeaderCell>
                  <Table.HeaderCell>Failed</Table.HeaderCell>
                  <Table.HeaderCell>Delivered</Table.HeaderCell>
                  <Table.HeaderCell>Total</Table.HeaderCell>
                  <Table.HeaderCell>Taps</Table.HeaderCell>
                  <Table.HeaderCell>Sent as Support</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {sortedNotifications?.length > 0 ? (
                  sortedNotifications?.map(
                    (tableDetails, index) => {
                      const setOnDate =
                        moment(tableDetails?.dateCreated).format(
                          "DD/MM/YYYY"
                        ) || "";
                      const setOnTime =
                        moment(tableDetails?.dateCreated).format(
                          "HH:mm"
                        ) || "";
                        const tapUserPercentage = tableDetails?.processedUsers > 0 ? ((tableDetails?.taps / tableDetails?.successCount) * 100) : 0;
                      return (
                        <Table.Row key={index}>
                          <Table.Cell>
                            {setOnDate} <br /> {setOnTime}
                          </Table.Cell>
                          <Table.Cell className="text-capitalize">
                            {tableDetails?.userType}
                          </Table.Cell>
                          <Table.Cell className="text-capitalize">
                            {tableDetails?.category?.toLowerCase()}
                          </Table.Cell>
                          <Table.Cell className="text-capitalize">
                            {tableDetails?.title}
                          </Table.Cell>
                          <Table.Cell>{tableDetails?.body}</Table.Cell>
                          <Table.Cell>{tableDetails?.failureCount}</Table.Cell>
                          <Table.Cell>{tableDetails?.successCount}</Table.Cell>
                          <Table.Cell>{tableDetails?.totalUsers}</Table.Cell>
                          <Table.Cell>
                            {tapUserPercentage}% <br />
                            {tableDetails?.taps ? `(${tableDetails?.taps})` : ""}
                          </Table.Cell>
                          <Table.Cell>
                            {tableDetails?.sendAsSupport ? "Yes" : "No"}
                          </Table.Cell>
                          <Table.Cell className="text-capitalize">
                            {tableDetails?.status?.toLowerCase()}
                          </Table.Cell>
                        </Table.Row>
                      );
                    }
                  )
                ) : (
                  <h3 className="text-danger mt-20 mb-20">No records found.</h3>
                )}
              </Table.Body>
            </Table>
          );
        }}
      </Connect>
    );
  };

  export default React.memo(FetchHistoryPerformanceTableData);