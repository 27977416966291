/* eslint-disable no-shadow */
import React, { useState, useEffect } from "react";
import { withAuthenticator, Connect } from "aws-amplify-react";
import { graphqlOperation } from "aws-amplify";
import {
  Button,
  Header,
  Icon,
  Table,
  Grid,
  Message,
  Image,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import BaseLayout from "../../components/templates/BaseLayout";
import { userEnquiriesByDate } from "../../graphql/queries";
import { deleteUserConversation, disableUser } from "../../services/AppData";

import "./styles.css";
import { BreadcrumbExampleShorthand, getMessageContent } from "../../components/molecules/functions";

const moment = require("moment");

const pBody = {
  conversationId: "",
};

const Enquiries = ({ location, history }) => {
  const pagingFromStorage = localStorage.getItem("pagingenq");
  const isFirstPage = location.search === "?p=1";
  const [callingAPI, setCallingAPI] = useState(false);
  const [pageMessage, setPageMessage] = useState({
    type: "none",
    header: "",
    message: "",
  });
  const [paging, setPaging] = useState(
    pagingFromStorage && !isFirstPage
      ? JSON.parse(pagingFromStorage)
      : { tokens: [], prev: null, next: null }
  );

  const Paging = ({ nextToken, tokens }) => {
    return (
      <Button.Group>
        {tokens.length > 1 && (
          <Button
            icon
            labelPosition="left"
            onClick={() => {
              tokens.splice(0, tokens.length);
              setPaging({
                
                tokens,
                prev: null,
                next: null,
              });
            }}
          >
            First
            <Icon name="fast backward" />
          </Button>
        )}
        {paging.next && (
          <Button
            icon
            labelPosition="left"
            onClick={() => {
              tokens.pop();
              setPaging({
                tokens,
                prev: tokens[tokens.length - 1],
                next: paging.prev,
              });
              history.push({
                pathname: `${window.location.pathname}`,
                search: "",
              });
            }}
          >
            Prev
            <Icon name="left arrow" />
          </Button>
        )}
        {nextToken && (
          <Button
            icon
            labelPosition="right"
            onClick={() => {
              tokens.push(nextToken);
              setPaging({
                tokens,
                prev: paging.next,
                next: nextToken,
              });
              history.push({
                pathname: `${window.location.pathname}`,
                search: "",
              });
            }}
          >
            Next
            <Icon name="right arrow" />
          </Button>
        )}
      </Button.Group>
    );
  };

  const TableView = ({ result }) => {
    const { items: enquiries, nextToken } = result;

    const { tokens } = paging;

    return (
      <>
        <Paging nextToken={nextToken} tokens={tokens} />
        <Table celled collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>Date Time</Table.HeaderCell>
              <Table.HeaderCell width={2}>User Name</Table.HeaderCell>
              <Table.HeaderCell>Message</Table.HeaderCell>
              <Table.HeaderCell width={2}>Maid Name</Table.HeaderCell>
              <Table.HeaderCell />
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <RowContent enquiries={enquiries} />
        </Table>
        <Paging nextToken={nextToken} tokens={tokens} />
      </>
    );
  };

  const deleteMessage = (conversationId) => {
    const confirmBox = window.confirm(
      "Do you really want to delete this conversation ?"
    );
    if (confirmBox === true) {
      setCallingAPI(true);
      pBody.conversationId = conversationId;

      // conversationId;
      // '10646ffb-c89b-4185-8444-e151aa22ec55';

      deleteUserConversation(JSON.stringify(pBody))
        .then((resData) => {
          if (resData && resData.status && resData.status === "OK") {
            if (resData.response && resData.response.data) {
              if (resData.response.data.result === "SUCCESS") {
                setPageMessage({
                  type: "success",
                  header: "Success",
                  message: "Conversation successfully deleted",
                });
              } else if (resData.response.data.result === "NOT_FOUND") {
                setPageMessage({
                  type: "negative",
                  header: "Failed",
                  message: "Failed to delete conversation (no data found)",
                });
              }
            } else {
              setPageMessage({
                type: "negative",
                header: "Failed",
                message: "Failed to delete conversation (server error)",
              });
            }
          } else {
            setPageMessage({
              type: "negative",
              header: "Failed",
              message: "Failed to delete conversation",
            });
          }
          setCallingAPI(false);
        })
        .catch((error) => {
          console.log("Error occured while deleting convesaton:", error);
          setCallingAPI(false);
        });
    }
  };

  const blockCustomer = (cusrtomerId) => {
    const confirmBox = window.confirm(
      "Do you really want to block this customer ?"
    );
    if (confirmBox === true) {
      setCallingAPI(true);
      let requestBody = { username: cusrtomerId };

      disableUser(JSON.stringify(requestBody))
        .then((resData) => {
          if (resData && resData.status && resData.status === "OK") {
            if (resData.response && resData.response.data) {
              if (resData.response.data.result === "SUCCESS") {
                setPageMessage({
                  type: "success",
                  header: "Success",
                  message: "User disabled successfully",
                });
              } else if (resData.response.data.result === "DISABLEDUSER") {
                setPageMessage({
                  type: "negative",
                  header: "Failed",
                  message: "This user is already in disabled status",
                });
              }
            } else {
              setPageMessage({
                type: "negative",
                header: "Failed",
                message: "Failed to disable user",
              });
            }
          } else {
            setPageMessage({
              type: "negative",
              header: "Failed",
              message: "Failed to delete conversation",
            });
          }
          setCallingAPI(false);
        })
        .catch((error) => {
          console.log("Error occured while deleting convesaton:", error);
          setCallingAPI(false);
        });
    }
  };

  const RowContent = ({ enquiries }) => (
    <Table.Body>
      {enquiries.map((enquiry) => {
        const { maid, user, lastMessage } = enquiry;
        const name = maid?.name ? JSON.parse(maid.name) : "";
        const dateCreated = moment(enquiry.createdAt, "x").format(
          "DD/MM/YYYY HH:mm"
        );

        if (enquiry.user && enquiry.createdAt) {
          return (
            <Table.Row key={enquiry.id}>
              <Table.Cell>
                <Link
                  to={`/conversation/${enquiry.id}?url=/enquiries?p=1&name=Enquiries`}
                >
                  {dateCreated || ""}
                </Link>
              </Table.Cell>
              <Table.Cell>
                <Link
                  to={`/customer/${enquiry.userId}?url=/enquiries?p=1&name=Enquiries`}
                >
                  {user.firstName || ""}
                </Link>
              </Table.Cell>
              <Table.Cell>
                {lastMessage[0]?.content &&
                lastMessage[0].content.startsWith("REVIEWREQUEST") ? (
                  "REVIEWREQUEST"
                ) : lastMessage[0]?.content ? (
                  getMessageContent(lastMessage[0].content)
                ) : lastMessage[0]?.image ? (
                  <Image src={lastMessage[0].image} size="small" />
                ) : (
                  ""
                )}
              </Table.Cell>
              <Table.Cell>
                <Link
                  to={`/maid/${enquiry.maidId}?url=/enquiries?p=1&name=Enquiries`}
                >
                  {name.firstName || ""}
                </Link>
              </Table.Cell>
              <Table.Cell>
                <Button
                  primary
                  onClick={() => {
                    deleteMessage(enquiry.id);
                  }}
                >
                  Delete messages
                </Button>
              </Table.Cell>
              <Table.Cell>
                <Button
                  secondary
                  onClick={() => {
                    blockCustomer(enquiry.userId);
                  }}
                >
                  Block customer
                </Button>
              </Table.Cell>
            </Table.Row>
          );
        }
        return null;
      })}
    </Table.Body>
  );

  const UserMessage = ({ pPageMessage }) => {
    const [show, setShow] = useState(true);
    useEffect(() => {
      const timeId = setTimeout(() => {
        setShow(false);
      }, 5000);

      return () => {
        clearTimeout(timeId);
      };
    }, []);

    if (!show) {
      return null;
    }
    if (pPageMessage.type === "success") {
      return (
        <Message
          success
          header={pPageMessage.header}
          content={pPageMessage.message}
        />
      );
    }
    if (pPageMessage.type === "negative") {
      return (
        <Message
          negative
          header={pPageMessage.header}
          content={pPageMessage.message}
        />
      );
    }
    return null;
  };

  const AllEnquiries = () => {
    const { next } = paging;
    const limit = 20;
    localStorage.setItem("pagingenq", JSON.stringify(paging));
    if (callingAPI) {
      return (
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>Just one second</Message.Header>
            Processing your request.
          </Message.Content>
        </Message>
      );
    }
    return (
      <Connect
        query={graphqlOperation(userEnquiriesByDate, {
          first: limit,
          after: next || null,
        })}
      >
        {({ data: { userEnquiriesByDate }, loading }) => {
          if (loading || !userEnquiriesByDate)
            return (
              <Message icon>
                <Icon name="circle notched" loading />
                <Message.Content>
                  <Message.Header>Just one second</Message.Header>
                  Loading...
                </Message.Content>
              </Message>
            );

          const { items } = userEnquiriesByDate;
          return (
            <>
              <Grid>
               {pageMessage.message && <Grid.Row>
                  <UserMessage pPageMessage={pageMessage} />
                </Grid.Row>}
                <Grid.Row>
                  <Grid.Column>
                    <Header as="h3" color="grey">
                      Displaying {items.length} enquiries
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row />
              </Grid>
              <TableView result={userEnquiriesByDate} />
            </>
          );
        }}
      </Connect>
    );
  };

  const breadCrumbs = [
    { key: "home", content: "Home", link: true, path: "/" },
    {
      key: "enquiries",
      content: "Enquiries",
      active: true,
    },
  ];

  return (
    <BaseLayout>
      <Grid divided>
        <div className="mt-15">{BreadcrumbExampleShorthand(breadCrumbs)}</div>
        <Grid.Row>
          <Grid.Column>
            <Header as="h2">Enquiries</Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <AllEnquiries />
    </BaseLayout>
  );
};

export default withAuthenticator(Enquiries);
