import moment from "moment";
import { Breadcrumb, Table } from "semantic-ui-react";
import React from "react";
import { Link } from "react-router-dom";

export const getLastActive = (lastActiveDate) => {
  const currentDate = Date.now(); // Current date in milliseconds

  const duration = moment.duration(currentDate - lastActiveDate);
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();

  let result = "";

  if (years > 0) {
    result += years + " year";
    if (years > 1) result += "s";
    result += " ";
  }
  if (months > 0) {
    result += months + " month";
    if (months > 1) result += "s";
    result += " ";
  }
  if (days > 0) {
    result += days + " day";
    if (days > 1) result += "s";
    result += " ";
  }

  result += "ago";

  return lastActiveDate ? result : "Ancient history";
};

export const NumberWithSeparator = (input) => {
  // Check if the input is a number or a string
  if (typeof input === "number") {
    return input.toLocaleString();
  } else if (typeof input === "string") {
    // Attempt to convert the string to a number
    const parsedNumber = parseFloat(input);

    // Check if the parsed value is a valid number
    if (!isNaN(parsedNumber)) {
      return parsedNumber.toLocaleString();
    } else {
      // If input is an invalid number string, return as is or handle error
      console.warn("Invalid number string:", input);
      return input; // Return original input or handle as needed
    }
  } else {
    // If input is not a string or number, return input or handle as needed
    console.warn("Invalid input type:", typeof input);
    return input;
  }
};

// Handle for breadcrumb func dynamically with page route.
export const BreadcrumbExampleShorthand = (options) => {
  // Filter valid breadcrumbs
  const validOptions = options?.filter(
    (breadcrumb) => breadcrumb && Object.keys(breadcrumb).length > 0 // Skip null and undefined // Skip empty objects
  );

  return (
    <Breadcrumb>
      {validOptions?.map((breadcrumb, index) => (
        <React.Fragment key={breadcrumb.key}>
          {breadcrumb.link && !breadcrumb.active ? (
            <Breadcrumb.Section>
              <Link to={breadcrumb.path}>{breadcrumb.content}</Link>
            </Breadcrumb.Section>
          ) : (
            <Breadcrumb.Section active={breadcrumb.active}>
              {breadcrumb.content}
            </Breadcrumb.Section>
          )}
          {/* Add the divider unless it's the last valid breadcrumb */}
          {index < validOptions.length - 1 && (
            <Breadcrumb.Divider icon="right angle" />
          )}
        </React.Fragment>
      ))}
    </Breadcrumb>
  );
};

export const getSessionStoreValue = (props) => {
  const seesionStorePrvNav = {
    state: {
      prevPathUrl: new URLSearchParams(props.location.search).get("url"),
      prevPathName: new URLSearchParams(props.location.search).get("name"),
    },
  };
  sessionStorage.setItem("prevPageDetails", JSON.stringify(seesionStorePrvNav));
  const prevNavLocation = JSON.parse(sessionStorage.getItem("prevPageDetails"));
  return prevNavLocation;
};

export const getMessageContent = (content) => {
  try {
    const parsedContent = JSON.parse(content);
    if (Array.isArray(parsedContent)) {
      return (
        <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Value</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {parsedContent?.map((item, index) => (
            <Table.Row key={index}>
              <Table.Cell className="text-capitalize">{item?.type}</Table.Cell>
              <Table.Cell>{item?.value}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      );
    }
  } catch (e) {
    return <span dangerouslySetInnerHTML={{ __html: content }} />
  }
};