import React, { useState } from "react";
import { List, Button, Icon, Message, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Connect } from "aws-amplify-react";
import { graphqlOperation } from "aws-amplify";
import {
  qrUserConversationsPage,
  allMessageConnection,
} from "../../../graphql/queries";

// const moment = require('moment');

const gridRowStyle = {
  padding: "0px 0px 5px 0px",
};

const gridStyle = {
  margin: 10,
};

const iconStyle = {
  marginLeft: "2px",
};

const repliedStyle = {
  opacity: 0.4,
};

const ConversationsListV2 = ({ maidId, customer }) => {

  const pagingFromStorage = localStorage.getItem("pagemaidconv");
  const isFirstPage = true; // location.search === '?p=1';
  // const [pageMessage, setPageMessage] = useState({ type: 'none', header: '', message: '' });
  const [paging, setPaging] = useState(
    pagingFromStorage && !isFirstPage
      ? JSON.parse(pagingFromStorage)
      : { tokens: [], prev: null, next: null }
  );
  const { next } = paging;
  const limit = 30;

  const Paging = ({ nextToken, tokens }) => {
    return (
      <Button.Group>
        {tokens.length > 1 && (
          <Button
            icon
            labelPosition="left"
            onClick={() => {
              tokens.splice(0, tokens.length);
              setPaging({
                tokens,
                prev: null,
                next: null,
              });
            }}
          >
            First
            <Icon name="fast backward" />
          </Button>
        )}
        {paging.next && (
          <Button
            icon
            labelPosition="left"
            onClick={() => {
              tokens.pop();
              setPaging({
                tokens,
                prev: tokens[tokens.length - 1],
                next: paging.prev,
              });
            }}
          >
            Prev
            <Icon name="left arrow" />
          </Button>
        )}
        {nextToken && (
          <Button
            icon
            labelPosition="right"
            onClick={() => {
              tokens.push(nextToken);
              setPaging({
                tokens,
                prev: paging.next,
                next: nextToken,
              });
            }}
          >
            Next
            <Icon name="right arrow" />
          </Button>
        )}
      </Button.Group>
    );
  };

  const TableView = ({ result }) => {
    const { userConversations, nextToken } = result;

    const { tokens } = paging;

    return (
      <>
        <Paging nextToken={nextToken} tokens={tokens} />
        <RowContent conversations={userConversations} />
        <Paging nextToken={nextToken} tokens={tokens} />
      </>
    );
  };

  const RowContent = ({ conversations }) => (
    <Grid style={gridStyle}>
      {conversations.map((conversation) => {
        const { conversationId, associated } = conversation;
        const associatedData =
          associated && associated.length > 0 ? associated[0] : undefined;

        if (conversationId && associatedData && associatedData.user) {
          // const dateCreated = moment(associatedData.conversation.createdAt, 'x').format(
          // 	'DD/MM/YYYY HH:mm'
          // );

          return (
            <Grid.Row style={gridRowStyle}>
              <Connect
                query={graphqlOperation(allMessageConnection, {
                  conversationId,
                })}
              >
                {({ data: { allMessageConnection }, loading }) => {
                  if (loading || !allMessageConnection)
                    return (
                      <List.Header
                        as={Link}
                        to={`/conversation/${conversationId}`}
                      >
                        {associatedData.user.firstName}{" "}
                        {associatedData.user.lastName}
                      </List.Header>
                    );
                  if (
                    customer &&
                    allMessageConnection.messages &&
                    allMessageConnection.messages.filter((msg) => {
                      return (
                        msg.author &&
                        msg.author.cognitoId &&
                        msg.author.cognitoId !== customer &&
                        msg.author.userType !== "Admin"
                      );
                    }).length > 0
                  )
                    return (
                      <List.Header
                        as={Link}
                        to={`/conversation/${conversationId}`}
                        style={repliedStyle}
                      >
                        {associatedData.user.firstName}{" "}
                        {associatedData.user.lastName}{" "}
                        <Icon name="reply" size="small" style={iconStyle} />
                      </List.Header>
                    );
                  if (
                    maidId &&
                    allMessageConnection.messages &&
                    allMessageConnection.messages.filter((msg) => {
                      return (
                        msg.author &&
                        msg.author.cognitoId &&
                        msg.author.cognitoId === maidId
                      );
                    }).length > 0
                  )
                    return (
                      <List.Header
                        as={Link}
                        to={`/conversation/${conversationId}?s=1&url=/maid/${maidId}&name=Maid`}
                        style={repliedStyle}
                      >
                        {associatedData.user.firstName}{" "}
                        {associatedData.user.lastName}{" "}
                        <Icon name="reply" size="small" style={iconStyle} />
                      </List.Header>
                    );
                  return (
                    <List.Header
                      as={Link}
                      to={`/conversation/${conversationId}?s=1&url=/maid/${maidId}&name=Maid`}
                    >
                      {associatedData.user.firstName}{" "}
                      {associatedData.user.lastName}{" "}
                    </List.Header>
                  );
                }}
              </Connect>
            </Grid.Row>
          );
        }
        return null;
      })}
    </Grid>
  );

  return (
    <Connect
      query={graphqlOperation(qrUserConversationsPage, {
        first: limit,
        after: next || null,
        userId: maidId,
      })}
    >
      {({ data: { userConversationsPage }, loading, error }) => {
        if (error) {
          console.log("Error ocured while fetching conversaiotns", error);
          return null;
        }
        if (!userConversationsPage || loading) {
          return (
            <Message>
              <Grid>
                <Grid.Row>
                  <Icon name="circle notched" size="small" loading />
                  <Message.Content>Loading...</Message.Content>
                </Grid.Row>
              </Grid>
            </Message>
          );
        }
        return <TableView result={userConversationsPage} />;
      }}
    </Connect>
  );
};

export default React.memo(ConversationsListV2);
