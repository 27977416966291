import React, { useEffect, useState } from "react";
import { withAuthenticator, Connect } from "aws-amplify-react";
import { API, graphqlOperation } from "aws-amplify";
import { Grid, Icon, Message } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import BaseLayout from "../../components/templates/BaseLayout";
import EditCustomer from "../../components/organisms/EditCustomer";
import { getMaidContactsAndActive, getUserById } from "../../graphql/queries";
import { getSessionStoreValue } from "../../components/molecules/functions";

const CustomerDetails = (props) => {
	const location = getSessionStoreValue(props);
	const { id } = useParams();
	const [customerData, setCustomerData] = useState(null);
	const [customerHeaderData, setCustomerHeaderData] = useState(null);
	const [callingAPI, setCallingAPI] = useState(false);

	useEffect(() => {
		const fetchCustomerData = async () => {
			setCallingAPI(true);
			try {
				const customerResponse = await API.graphql(
					graphqlOperation(getUserById, { cognitoId: id })
				);
				const contactsResponse = await API.graphql(
					graphqlOperation(getMaidContactsAndActive, {
						userId: id,
						userType: "Customers",
					})
				);
				setCustomerData(customerResponse.data.getUserById);
				setCustomerHeaderData(contactsResponse.data.getMaidContactsAndActive);
				setCallingAPI(false);
			} catch (error) {
				setCallingAPI(false);
				console.error("Error fetching data:", error);
			}
		};

		fetchCustomerData();
	}, [id]);

	return (
		<BaseLayout>
			{callingAPI ? (
				<Message icon>
					<Icon name="circle notched" loading />
					<Message.Content>
						<Message.Header>Just one second</Message.Header>
						Loading...
					</Message.Content>
				</Message>
			) : (
					<Grid>
						{customerData !== null && customerHeaderData !== null ? (
							<EditCustomer
								id={id}
								customer={customerData}
								customerHeaderData={customerHeaderData}
								location={location}
							/>
						): <Message
						className="error-block"
						error
						header="There were errors with the customer profile."
						list={[
						  "Customer details should not be retrieved from the server.",
						  "It might be possible to submit unnecessary details through forced input.",
						]}
					  />}
					</Grid>
			)}
		</BaseLayout>
	);

	// return (
	// 	<Connect query={graphqlOperation(getUserById, { cognitoId: id })}>
	// 		{({ data: { getUserById: customer }, loading }) => {
	// 			if (loading || !customer) return <h3>Loading...</h3>;
	// 			return <EditCustomer customer={customer} id={id} />;
	// 		}}
	// 	</Connect>
	// );
};

// const Customer = () => {
// 	const { id } = useParams();
// 	return (
// 		<BaseLayout>
// 			<Grid>
// 				<CustomerDetails id={id} />
// 			</Grid>
// 		</BaseLayout>
// 	);
// };

export default withAuthenticator(CustomerDetails);
