import React, { useEffect, useState } from "react";
import { withAuthenticator } from "aws-amplify-react";
import { API, graphqlOperation } from "aws-amplify";
import {
  Button,
  Header,
  Label,
  Table,
  Grid,
  Message,
  Icon,
  Form,
} from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import BaseLayout from "../../components/templates/BaseLayout";
import { allSupportConversations } from "../../graphql/queries";
import { BreadcrumbExampleShorthand } from "../../components/molecules/functions";
import moment from "moment";
import "./styles.css";

const SupportChats = () => {
  const history = useHistory();
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSearchType, setSelectedSearchType] = useState("email");
  const [tempSearchType, setTempSearchType] = useState("email");
  const [searchText, setSearchText] = useState("");
  const [searchValue, setSearchValue] = useState("");

  // Pagination state
  const [paginationState, setPaginationState] = useState({
    currentToken: null,
    tokenHistory: [], // Store previous tokens for back navigation
    limit: 10,
  });

  // Breadcrumbs configuration
  const breadCrumbs = [
    { key: "home", content: "Home", link: true, path: "/" },
    { key: "supportchat", content: "Support Chats", active: true },
  ];

  // Fetch data on initial load and when pagination or search changes
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Prepare query parameters
        const queryParams = {
          limit: paginationState.limit,
          searchType: selectedSearchType,
          searchText: searchValue,
        };

        // Only include nextToken when not searching
        if (!searchValue) {
          queryParams.nextToken = paginationState.currentToken;
        }

        const response = await API.graphql(
          graphqlOperation(allSupportConversations, queryParams)
        );

        const result = response.data.getSupportChatList;
        setCurrentData(result);
      } catch (err) {
        setError(err);
        console.error("Error fetching conversations:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    paginationState.currentToken,
    paginationState.limit,
    searchValue,
    selectedSearchType,
  ]);

  // Handle "Next" button click
  const handleNextPage = () => {
    if (currentData?.nextToken) {
      // Store current token in history before moving to next page
      setPaginationState((prev) => ({
        ...prev,
        tokenHistory: [...prev.tokenHistory, prev.currentToken],
        currentToken: currentData.nextToken,
      }));

      // Update URL to reflect page change
      history.push({
        pathname: window.location.pathname,
        search: "",
      });
    }
  };

  // Handle "Previous" button click
  const handlePrevPage = () => {
    if (paginationState.tokenHistory.length > 0) {
      // Get the last token from history
      const newTokenHistory = [...paginationState.tokenHistory];
      const prevToken = newTokenHistory.pop();

      setPaginationState((prev) => ({
        ...prev,
        tokenHistory: newTokenHistory,
        currentToken: prevToken,
      }));

      // Update URL to reflect page change
      history.push({
        pathname: window.location.pathname,
        search: "",
      });
    }
  };

  // Handle "First Page" button click
  const handleFirstPage = () => {
    setPaginationState((prev) => ({
      ...prev,
      tokenHistory: [],
      currentToken: null,
    }));
  };

  // Handle search
  const handleSearch = () => {
    setSearchValue(searchText);
    setSelectedSearchType(tempSearchType);
    // Reset pagination when searching
    setPaginationState((prev) => ({
      ...prev,
      currentToken: null,
      tokenHistory: [],
    }));
  };

  // Handle search reset
  const handleResetSearch = () => {
    setSearchText("");
    setSearchValue("");
    setSelectedSearchType("email");
    setTempSearchType("email");
    // Reset pagination
    setPaginationState((prev) => ({
      ...prev,
      currentToken: null,
      tokenHistory: [],
    }));
  };

  // Determine if pagination should be shown (hide when searching)
  const showPagination = !searchValue;

  return (
    <BaseLayout>
      <Grid divided>
        <div className="mt-15">{BreadcrumbExampleShorthand(breadCrumbs)}</div>
        <Grid.Row>
          <Grid.Column>
            <Header as="h2">Support Chats</Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {/* Properly pass props to SearchFilterSection */}
      <div className="justify-content-between card-item search-info">
        <Form className="w-100">
          <Form.Select
            className="city-filter"
            label="Search Type"
            placeholder={"Select Search Type"}
            options={[
              {
                key: "email",
                text: "Email",
                value: "email",
              },
              {
                key: "firstName",
                text: "First Name",
                value: "firstName",
              },
              {
                key: "lastName",
                text: "Last Name",
                value: "lastName",
              },
            ]}
            value={tempSearchType}
            onChange={(event, { value }) => {
              event.preventDefault();
              setSearchText("");
              setTempSearchType(value);
            }}
          />
          <Form.Input
            label="Search Text"
            placeholder={`Enter ${tempSearchType.toLowerCase() ||
              "value"}`}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Form>

        <Button className="mr-8 mt-20" color="green" onClick={handleSearch}>
          Search
        </Button>
        <Button className="mt-20" color="red" onClick={handleResetSearch}>
          Reset
        </Button>
      </div>

      {/* Only show pagination when not searching */}
      {showPagination && (
        <PagingControls
          hasNextToken={!!currentData?.nextToken}
          hasPrevTokens={paginationState.tokenHistory.length > 0}
          onFirstPage={handleFirstPage}
          onPrevPage={handlePrevPage}
          onNextPage={handleNextPage}
        />
      )}

      {loading ? (
        <LoadingMessage />
      ) : error ? (
        <Message error content={`Error: ${error.message}`} />
      ) : (
        <TableView conversations={currentData?.items || []} />
      )}
    </BaseLayout>
  );
};

// Pagination controls component
const PagingControls = ({
  hasNextToken,
  hasPrevTokens,
  onFirstPage,
  onPrevPage,
  onNextPage,
}) => {
  return (
    <div className="mt-20">
      <Button.Group>
        {hasPrevTokens && (
          <Button icon labelPosition="left" onClick={onFirstPage}>
            First
            <Icon name="fast backward" />
          </Button>
        )}

        {hasPrevTokens && (
          <Button icon labelPosition="left" onClick={onPrevPage}>
            Prev
            <Icon name="left arrow" />
          </Button>
        )}

        <Button
          icon
          labelPosition="right"
          onClick={onNextPage}
          disabled={!hasNextToken}
        >
          Next
          <Icon name="right arrow" />
        </Button>
      </Button.Group>
    </div>
  );
};

// Loading message component
const LoadingMessage = () => (
  <Message icon>
    <Icon name="circle notched" loading />
    <Message.Content>
      <Message.Header>Just one second</Message.Header>
      Processing your request.
    </Message.Content>
  </Message>
);

// Table component for displaying conversations
const TableView = ({ conversations }) => (
  <div className="support-chat-table">
    {conversations?.length > 0 ? (
      <Table basic="very" celled collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>User</Table.HeaderCell>
            <Table.HeaderCell>User Type</Table.HeaderCell>
            <Table.HeaderCell>Date reported</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <ConversationRows conversations={conversations} />
      </Table>
    ) : (
      <h3 className="text-danger">No conversations found.</h3>
    )}
  </div>
);

// Table rows component for conversations
const ConversationRows = ({ conversations }) => (
  <Table.Body>
    {conversations?.map((conversation) => {
      const {
        id,
        createdAt,
        user: { cognitoId, firstName, lastName, email, userType },
        isReaded,
        messageCount,
      } = conversation;

      const profileUrl =
        userType === "Customers"
          ? `/customer/${cognitoId}?url=/supportchats&name=Support Chats`
          : `/maid/${cognitoId}?url=/supportchats&name=Support Chats`;

      const formattedDate = moment(createdAt, "x").format(
        "MMMM Do YYYY, h:mm:ss a"
      );
      const displayName = `${firstName} ${lastName || ""}`;
      const userTypeDisplay = userType === "Customers" ? "Client" : "Maid";

      return (
        <Table.Row key={id} className="w-100">
          <Table.Cell>
            <Label as="a" href={profileUrl} content={displayName} />
            {isReaded === false && messageCount > 0  && (
              <img
                src="/images/dot.gif"
                alt="Unread"
                height="30"
                style={{ marginLeft: `-15px`, verticalAlign: "bottom" }}
              />
            )}
          </Table.Cell>
          <Table.Cell>{userTypeDisplay}</Table.Cell>
          <Table.Cell>{formattedDate}</Table.Cell>
          <Table.Cell>
            <a href={`mailto:${email || ""}`}>{email || ""}</a>
          </Table.Cell>
          <Table.Cell>
            <Button
              color={messageCount > 0 ? "blue" : ""}
              as={Link}
              to={`/conversation/${id}?s=1&url=/supportchats&name=Support Chats`}
              disabled={messageCount <= 0}
            >
              {messageCount > 0 ?"View conversation" : "No conversation"}
            </Button>
            <br />
          </Table.Cell>
        </Table.Row>
      );
    })}
  </Table.Body>
);

export default withAuthenticator(SupportChats);
