import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Grid, Icon, Message } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import BaseLayout from "../../components/templates/BaseLayout";
import EditMaid from "../../components/organisms/EditMaid";
import { getMaid, getMaidContactsAndActive } from "../../graphql/queries";

import "./styles.css";
import { getSessionStoreValue } from "../../components/molecules/functions";

const Maid = (props) => {
  const location = getSessionStoreValue(props);

  const { id } = useParams();
  const [maidData, setMaidData] = useState(null);
  const [maidHeaderData, setMaidHeaderData] = useState(null);
  const [callingAPI, setCallingAPI] = useState(false);

  useEffect(() => {
    const fetchMaidData = async () => {
      setCallingAPI(true);
      try {
        const maidResponse = await API.graphql(
          graphqlOperation(getMaid, { userId: id })
        );
        const contactsResponse = await API.graphql(
          graphqlOperation(getMaidContactsAndActive, {
            userId: id,
            userType: "Maids",
          })
        );

        const maid = maidResponse.data.getMaid;
        const contacts = contactsResponse.data.getMaidContactsAndActive;

        maid.name = maid?.name ? JSON.parse(maid.name) : "";
        maid.address = JSON.parse(maid.address);

        setMaidData(maid);
        setMaidHeaderData(contacts);
        setCallingAPI(false);
      } catch (error) {
        setCallingAPI(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchMaidData();
  }, [id]);

  return (
    <BaseLayout>
      {callingAPI ? (
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>Just one second</Message.Header>
            Loading...
          </Message.Content>
        </Message>
      ) : (
        <Grid>
          {maidData !== null && maidHeaderData !== null ? (
            <EditMaid
              id={id}
              maid={maidData}
              maidHeaderData={maidHeaderData}
              location={location}
            />
          ) : (
            <Message
              className="error-block"
              error
              header="There were errors with the maid profile."
              list={[
                "Maid details should not be retrieved from the server.",
                "It might be possible to submit unnecessary details through forced input.",
              ]}
            />
          )}
        </Grid>
      )}
    </BaseLayout>
  );
};

export default Maid;
